
import * as React from 'react'
import { graphql } from 'gatsby'

import BlogTile from '../components/blogTile'
import Layout from '../components/layout'

import * as Styles from '../styles/layout.module.scss'
 
const CareerPage = ({ pageContext, data }) => {
    var translationContext = { locale: 'de',
                               defaultMenu: false };
    return (
        <Layout title="Karriere"
            pageContext={pageContext}
            translationContext={translationContext}>
            <div class={Styles.singleTopicLayout}>
              <main>
            <div class={Styles.centeredLayout}>
                <h1>Karriere bei Brandeis Consulting</h1>
                <p>Wir suchen nach Verstärkung. Allerdings haben wir bei Brandeis Consulting keine zu 100% vordefinierten <a href="https://help.sap.com/saphelp_gbt10/helpdata/DE/bb/bdb0d0575911d189240000e8323d3a/content.htm?no_cache=true" target="_blank" rel="noreferrer noopener">Planstellen</a>.
                    Jeder Mensch hat andere Kenntnisse, Erfahrungen und Interessen. Entsprechend sind auch die folgenden Punkte nur eine grobe Richtlinie.</p>
                <p>Auch wenn unten in den Stellenangeboten Dein Traumjob noch nicht ausgeschrieben ist, freuen wir uns über Initiativbewerbungen. Denn vielleicht können wir ja die für Dich passende Stelle ja erschaffen. Wenn Du der Meinung bist, dass das zu Deinen Qualifikation und Interessen passt, dann bewirb Dich doch einfach. Und auch, wenn Du glaubst, dass Du unser Portfolio vielleicht noch um andere spannende Themen ergänzen kannst.</p>
                <div></div>
                <div class={Styles.columns}>
                    <div class={Styles.block}>
                        <h2>Produktbereiche und Technologien</h2>
                        <p>Wir bieten bislang vor allem für die folgenden Themen Schulungen und Beratung an. Wir freuen uns aber auch über neues. Melde Dich doch, wenn Du weitere Themen hast, die Du virtuos beherrschst. Dann können wir vielleicht gemeinsam unser Portfolio erweitern.</p>
                        <ul>
                            <li>SAP BW/4HANA</li>
                            <li>SAP HANA</li>
                            <li>SAP Data Warehouse Cloud (DWC)</li>
                            <li>ABAP OO</li>
                            <li>SQLScript</li>
                            <li>CDS ABAP</li>
                            <li>AMDP</li>
                        </ul>
                        <p>
                        </p>
                    </div>
                    <div class={Styles.block}>
                        <h2>Aufgaben</h2>
                        <p>Es gibt bei uns vielfältige Aufgaben, die unser Geschäft voran bringen. Hier eine unvollständige Liste von Tätigkeiten, die bei uns anfallen:</p>
                        <ul>
                            <li>Durchführung von <strong>Schulungen</strong>. Online oder beim Kunden vor Ort.</li>
                            <li>Durchführung von <strong>Beratungsprojekten</strong>
                            </li>
                            <li>Erstellung von <strong>Schulungsmaterial</strong>
                            </li>
                            <li>Erstellung von <strong>E-Learning Content</strong>, als Videos oder mit <a href="https://hwc.brandeis.de/exercise?viewname=IframeCourse&amp;lesson=2" target="_blank" data-type="URL" data-id="https://hwc.brandeis.de/exercise?viewname=IframeCourse&amp;lesson=2" rel="noreferrer">interaktiven Übungen</a>
                            </li>
                            <li>Durchführung von <strong>Sprechstunden</strong>
                            </li>
                            <li>
                                <strong>Vorträge </strong>bei Community Veranstaltungen, z.B. SAP Online Tracks oder HANA TechNights</li>
                            <li>Schreiben von <strong>Artikeln</strong>, <strong>Blogposts </strong>und <strong>Büchern</strong>
                            </li>
                            <li>
                                <strong>Website </strong>mit CMS System aktualisieren</li>
                        </ul>
                    </div>
                </div>
                <div class={Styles.columns}>
                    <div class={Styles.block}>
                        <h2>Voraussetzung und Anspruch</h2>
                        <p>Es gibt viele positive Eigenschaften, die Sie mitbringen können. Hier sind ein paar der wichtigsten:</p>
                        <ul>
                            <li>
                                <strong>Hervorragende Kenntnisse</strong> &#8211; Unsere Kunden buchen uns, weil wir uns von der breiten Masse abheben. Weil wir nicht nur ein weiteres Teammitglied sind, sondern weil wir den Unterschied machen. Andere sollen von uns lernen können, nicht nur wenn wir in der Rolle des Trainer sind.</li>
                            <li>
                                <strong>Qualität </strong>&#8211; Als Trainer und Berater haben wir einen hohen Anspruch an unsere Arbeitsergebnisse. Code wird bei uns stets nach den <a href="https://de.wikipedia.org/wiki/Clean_Code" target="_blank" data-type="URL" data-id="https://de.wikipedia.org/wiki/Clean_Code" rel="noreferrer noopener">Clean Code Prinzipien</a> geschrieben und wir gehen <a href="https://www.ionos.de/digitalguide/websites/web-entwicklung/technische-schulden-erklaert/" target="_blank" data-type="URL" data-id="https://www.ionos.de/digitalguide/websites/web-entwicklung/technische-schulden-erklaert/" rel="noreferrer noopener">keine technischen Schulden</a> ein. Auch wenn uns der Kunde dazu drängt.</li>
                            <li>
                                <strong>Spaß an der Arbeit</strong> &#8211; Wer Arbeit als notwendiges Übel und Last empfindet, ist bei uns falsch. Wir suchen Mitarbeiter, die sich für Themen begeistern können und die Spaß daran haben, etwas gestalten.</li>
                            <li>
                                <strong>Neugierde</strong> &#8211; auf neue Themen und Herausforderungen. Wir wissen heute noch nicht, wie sich die Technik und der Markt entwickeln. Aber wir haben Spaß daran, unser Wissen stets auf dem aktuellen Stand zu halten.</li>
                        </ul>
                    </div>
                    <div class={Styles.block}>
                        <h2>Was wir bieten</h2>
                        <p>Es wird oft gesagt: Es kommt immer auf das Gesamtpaket an und nicht nur auf einzelne Komponenten. Aber jeder hat andere Dinge, die für ihn wichtig sind. Deshalb veröffentlichen wir hier keine feste Liste mit Gehaltsbestandteilen (iPhone 15, Porsche, Bahncard 100 First etc. ). Was für Euch wichtig ist, sollten wir im persönlichen Gespräch klären. Wir können nur sagen: Es soll sich für beide Seiten lohnen.</p>
                        <p>Auf jeden Fall bieten wir:</p>
                        <ul>
                            <li>Viele Freiräume, die gestaltet werden können. Unser Unternehmen ist jung und im Wachstum. Darum gibt es jede Menge Möglichkeiten, eigene Ideen einzubringen und seine eigene Arbeitsstelle mit zu formen.</li>
                            <li>Eine gute Vergütung mit flexiblem Anteil, so dass gute Leistungen auch angemessen honoriert werden können. Die Mitarbeiter sollen am Erfolg des Unternehmens beteiligt sein.</li>
                            <li>Einen modernen Arbeitsplatz in unserem Büro im <a href="https://mafinex.next-mannheim.de/" data-type="URL" data-id="https://mafinex.next-mannheim.de/" target="_blank" rel="noreferrer">MAFINEX Technologiezentrum</a>. Das ist top ausgestattet und hervorragend zu erreichen mit Auto und Bahn.</li>
                        </ul>
                        <p>
                        </p>
                    </div>
                </div>
                <h2>Unsere Stellenangebote</h2>
                <p>Wie oben schon geschrieben: Wir haben keine fest definierten Planstellen. Darum tuen wir uns mit konkreten Stellenbeschreibungen auch schwer. Wenn diese nicht 100% auf Dich passen, dann schreibe doch Deine eigene Stellenbeschreibung und bewirb Dich damit. Wir werden dann sehen, wie das passen kann.</p>
                <div>
                    <div class={Styles.tileGrid}>
                        {
                            data.allMdx.nodes.map(node => (
                                <BlogTile blogNode={node} />
                            ))
                        }


                    </div>
                </div>

            </div>
            </main>
            </div>
        </Layout>
    )
}

export const query = graphql`
{
    allMdx(
      filter: {frontmatter: {date: {ne: "null"}, locale: {eq: "de"}, categories: {eq: "career"}}}
      sort: {fields: frontmatter___date, order: DESC}
    ) {
      nodes {
        frontmatter {
          date(formatString: "D. MMMM YYYY", locale: "DE")
          title
          hero_image {
            childImageSharp {
              gatsbyImageData(width: 300, height: 150)
            }
          }
        }
        id
        slug
        excerpt
      }
    }
  }
`
export default CareerPage